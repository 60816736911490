// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-post-js": () => import("/opt/build/repo/src/templates/post/post.js" /* webpackChunkName: "component---src-templates-post-post-js" */),
  "component---src-templates-articles-articles-js": () => import("/opt/build/repo/src/templates/articles/articles.js" /* webpackChunkName: "component---src-templates-articles-articles-js" */),
  "component---src-templates-archive-archive-js": () => import("/opt/build/repo/src/templates/archive/archive.js" /* webpackChunkName: "component---src-templates-archive-archive-js" */),
  "component---src-templates-post-history-of-theater-post-history-of-theater-js": () => import("/opt/build/repo/src/templates/post-history-of-theater/post-history-of-theater.js" /* webpackChunkName: "component---src-templates-post-history-of-theater-post-history-of-theater-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-js": () => import("/opt/build/repo/src/pages/about-me.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-about-us-js": () => import("/opt/build/repo/src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-history-of-theater-js": () => import("/opt/build/repo/src/pages/history-of-theater.js" /* webpackChunkName: "component---src-pages-history-of-theater-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

